import * as Sentry from "@sentry/browser";
import { rewriteFramesIntegration } from "@sentry/browser";

export function configure(properties) {
  Sentry.init({
    dsn: properties.dsn,
    release: properties.release,
    environment: properties.environment,
    dist: properties.dist,
    integrations: [
      Sentry.browserTracingIntegration(),
      rewriteFramesIntegration({
        iteratee: rewriteStackFrame,
      }),
    ],
    tracesSampleRate: 0.3,
  });
}

function rewriteStackFrame(frame) {
  if (frame.filename) {
    return {
      ...frame,
      filename: removeHashFromFilename(frame.filename),
    };
  }
  return frame;
}

function removeHashFromFilename(filename) {
  return filename.replace(/(.*)(-\S+)(\..*)/gm, "$1$3");
}
